import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
export default function Secfive(){
    return (
        <>
        
             <Container fluid className="__bg-primary-sl-mirror">
                <Container>
                    <Row className="fade-in">
                        <Col lg={12}>
                            <div className="__introduction">
                                <p className="text-center">
                                    Introducing
                                </p>
                            </div>
                        </Col>
                        <Col lg={12}>
                            <div className="__image_logo-sl">
                                <img 
                                    src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/attraction-mastery/logo-am-love.png"
                                    alt="logo" 
                                    className="img img-fluid lazyload"/>
                            </div>
                        </Col>
                    </Row>
                    <Row className="fade-in __margin-top-sl-50">
                        <Col lg={12}>    
                            <div className="__wrappe-headline-sl">
                                <h2 className="text-white text-center">Apa saja yang Kamu pelajari setelah ikut training ini?</h2>
                            </div>
                        </Col>
                    </Row>
                   
                    <Row>
                        <Col lg={4} md={4}>
                            <div className="pricing mb-3">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body">
                                        <h5 className="card-title text-uppercase text-center">Secret 1</h5>
                                        <hr/>
                                        <ul className="fa-ul">
                                        <li>
                                            <span className="fa-li"> 
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>
                                            </span>
                                            Mitos-mitos Ketertarikan
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>
                                            </span>
                                            Mengapa kamu sering tertarik pada seseorang tanpa tahu sebabnya
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>
                                            </span>
                                            Rahasia ketertarikan yang tidak diketahui orang umum
                                        </li>
                                        </ul>
                                       
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4}>
                            <div className="pricing mb-3">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body">
                                        <h5 className="card-title text-uppercase text-center">Secret 2</h5>
                                        <hr/>
                                        <ul className="fa-ul">
                                        <li>
                                            <span className="fa-li"> 
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>
                                            </span>
                                            Tanam benih cinta fisik dan cara installnya
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>
                                            </span>
                                            Tanam benih cinta emosional dan cara installnya
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>
                                            </span>
                                            Tanam benih cinta mental dan cara installnya
                                        </li>
                                        </ul>
                                       
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col lg={4} md={4}>
                            <div className="pricing mb-3">
                                <div className="card mb-5 mb-lg-0">
                                    <div className="card-body">
                                        <h5 className="card-title text-uppercase text-center">Secret 3</h5>
                                        <hr/>
                                        <ul className="fa-ul">
                                        <li>
                                            <span className="fa-li"> 
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>
                                            </span>
                                            Tanam benih cinta di pertemuan pertama
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>
                                            </span>
                                            Tanam benih cinta pada sahabat
                                        </li>
                                        <li>
                                            <span className="fa-li">
                                                <FontAwesomeIcon icon={faCheckCircle} className="__fa-check-circle-sl" style={{color:"green"}}/>
                                            </span>
                                            Tanam benih cinta pada teman/kenalan yang belum akrab
                                        </li>
                                        </ul>
                                       
                                    </div>
                                </div>
                            </div>
                        </Col>
                        </Row>

                </Container>
            </Container>
           

        </>
    )
}

